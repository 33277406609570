export const environment = {
    production: true,
    envType: 'staging',
    pusher: {
        key: 'd7a8449f8108009f8281',
        cluster: 'eu',
    },
    openAi: {
        key: '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',
    },
    aesKey: 'lBn9fBF6jTmwZc7WPwsjEsuO1qsciljSqF3MYc3u',
    recaptcha: { siteKey: '6LdYbKMmAAAAANy4A9zIMnAckv1JqXzcRLngvgKj' },
    apiUrl: 'https://api-latest.expepharma.com',
};
